.ant-descriptions {
  .ant-descriptions-header {
    .ant-descriptions-title {
      font-size: 1.5rem;
    }
  }

  .ant-descriptions-view {
    @apply bg-white rounded-xl overflow-hidden shadow;
  }
}

.ant-descriptions.noBackground {
  .ant-descriptions-view {
    @apply bg-transparent shadow-none;

    tbody {
      @apply divide-y-2 divide-white;
    }

    .ant-descriptions-row {
      .ant-descriptions-item {
        padding: 10px 0px;
      }
    }
    .ant-descriptions-row:first-of-type {
      .ant-descriptions-item {
        padding: 0px 0px 10px;
      }
    }

    .ant-descriptions-item-label {
      font-weight: 500;
    }
  }
}
