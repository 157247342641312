.title {
  @apply text-xl font-semibold tracking-tight;
}

.subtitle {
  @apply text-base font-semibold tracking-tight;
}

.basic-label {
  @apply block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5;
}
