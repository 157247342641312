.ant-card.cardGrayTitle {
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  overflow: hidden;

  .ant-card-head {
    padding: 0 14px;
    background-color: rgb(243, 243, 243);

    .ant-card-head-wrapper {
      .ant-card-head-title {
        padding: 12px 0;
      }
    }
  }

  .ant-card-body {
    padding: 14px;
    flex-grow: 2;
  }

  .ant-card-actions {
    li {
      margin: 6px 0;
    }
  }
}
