@import '../variables/colors.scss';
@import '../variables/layout.scss';

.ant-menu-item-selected a, 
.ant-menu-submenu-selected > .ant-menu-submenu-title {
    font-weight: 600;
}
  
.ant-menu-item {
    a {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    img {
        margin-right: 10px;
    }
}

.ant-menu-inline {
    border-right: 0;
}
  
.ant-menu-inline .ant-menu-item, .ant-menu-inline .ant-menu-submenu-title {
    margin-bottom: unset;
    margin-top: unset;
    padding-top: 4px;
    padding-bottom: 4px;
    height: unset !important;
    width: unset;
}

.ant-menu-inline .ant-menu-submenu .ant-menu-item {
    padding-top: 10px;
    padding-bottom: 10px;
    a {
        line-height: normal;
    }
}

.ant-menu-submenu-inline .ant-menu-submenu-inline .ant-menu-inline {
    margin-left: 48px;
    border-left: 2px solid $block-link--selected;
    width: unset;
    .ant-menu-item {
        padding-left: 12px !important;
    }
}

.ant-menu-submenu-selected > .ant-menu-submenu-title, 
.ant-menu-submenu-open > .ant-menu-submenu-title, 
.ant-menu-sub.ant-menu-inline,
.ant-menu-item:hover, 
.ant-menu-submenu-title:hover {
    background-color: $block-link--hover;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: $block-link--selected;
}

.ant-menu-inline .ant-menu-item::after {
    border-right-color: $color-700;
}

.ant-menu.ant-menu-inline-collapsed {
    width: $sider-width-collapsed !important;
    
    
    .ant-menu-item {
        display: flex;
        justify-content: center;
        padding: 0 !important;
        
        a {
            display: none;
        }
        img {
            margin-right: 0;
        }
    }
    

    > .ant-menu-item, > .ant-menu-submenu > .ant-menu-submenu-title {
        display: flex;
        align-items: center;
    }
}