@import '../variables/colors.scss';
@import '../variables/layout.scss';
@tailwind base;
@tailwind components;
@tailwind utilities;

.ant-tabs-nav {
  // @apply px-4 sm:px-6 lg:px-8;
  height: 56px;
}

.ant-tabs-tab {
  @apply font-semibold;
}

.ant-tabs.titleSubTitle {
  .ant-tabs-nav {
    .ant-tabs-nav-add,
    .ant-tabs-tab {
      &:hover,
      &:focus {
        color: $color-400;
      }
    }

    .ant-tabs-tab {
      padding: 0px;

      .ant-tabs-tab-btn {
        height: 100%;
        p {
          text-align: center;
        }
        p:last-of-type {
          font-size: 12px;
          font-weight: 400;
        }

        &:focus,
        &:active {
          color: $color-400;
        }
      }
    }

    .ant-tabs-extra-content {
      height: 100%;

      .btn-actions {
        height: 100%;
        width: 40px;
        margin-left: 2px;
        border: 1px solid #f0f0f0;
        background: #fafafa;

        span {
          color: gray;
        }

        &:hover > span {
          color: black;
        }
      }
    }
  }
}
