@import './forms';
@import './components';
@import './typography';
@import './buttons';

@import '../../node_modules/tailwindcss/base';
@import '../../node_modules/tailwindcss/components';
@import '../../node_modules/tailwindcss/utilities';

.custom-card {
  border-radius: 4px !important;
  padding: 8px !important;
}

.custom-card .ant-card-body {
  padding: 8px !important;
}

.custom-card-align-center .ant-card-head {
  text-align: center !important;
}

.bg-elior-600 {
  background-color: $color-600 !important;
}
