.ant-dropdown {
  .ant-dropdown-menu {
    @apply rounded-md overflow-hidden p-0;

    .ant-dropdown-menu-item {
      @apply text-joyfood-900 hover:text-white border-joyfood-700 hover:bg-joyfood-700;
    }

    .ant-dropdown-menu-submenu {
      & > div {
        @apply text-joyfood-900 hover:text-white border-joyfood-700 hover:bg-joyfood-700;

        &:hover {
          .ant-dropdown-menu-submenu-expand-icon {
            .ant-dropdown-menu-submenu-arrow-icon {
              @apply text-white;
            }
          }
        }

        .ant-dropdown-menu-submenu-expand-icon {
          bottom: 8px;

          .ant-dropdown-menu-submenu-arrow-icon {
            @apply text-joyfood-900;
          }
        }
      }
    }
  }
}

.ant-menu-submenu {
  .ant-dropdown-menu {
    @apply rounded-md overflow-hidden p-0;

    .ant-dropdown-menu-item {
      @apply text-joyfood-900 hover:text-white border-joyfood-700 hover:bg-joyfood-700;
    }
  }
}
